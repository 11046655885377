import i18n from "@/libs/i18n"

export default [
    {
        path: "/management/users",
        name: "list-users",
        component: () =>
            import("@/views/app/management/users/users-list/UsersList.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.users.description",
                index: 2,
            },
            pageTitle: i18n.tc("modules.users.description", 2),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.users.description",
                        index: 2,
                    },
                    active: true,
                },
            ],
            action: "list",
            resource: "users",
        },
    },
    {
        path: "/management/users/:id",
        name: "view-users",
        component: () =>
            import("@/views/app/management/users/users-view/UsersView.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.users.description",
                index: 1,
            },
            pageTitle: i18n.tc("modules.users.description", 1),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.users.description",
                        index: 2,
                    },
                    to: { name: "list-users" },
                },
                {
                    i18nText: {
                        t: "modules.users.details",
                    },
                    active: true,
                },
            ],
            action: "view",
            resource: "users",
        },
    },
    {
        path: "/management/users/:id/edit",
        name: "edit-users",
        component: () =>
            import("@/views/app/management/users/users-edit/UsersEdit.vue"),
        meta: {
            i18nTitle: {
                t: "modules.users.details",
            },
            pageTitle: i18n.t("modules.users.details"),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.users.description",
                        index: 2,
                    },
                    to: { name: "list-users" },
                },
                {
                    i18nText: {
                        t: "modules.users.details",
                    },
                    active: true,
                },
            ],
            action: "edit",
            resource: "users",
        },
    },
]

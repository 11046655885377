<template>
    <b-card
        class="pb-0 mb-0 pr-0 pl-0"
        :bodyClass="cardBodyClass ? cardBodyClass : 'p-2'"
    >
        <emailForm
            v-if="resourceData"
            :id="emailModalId"
            :resourceData="resourceData"
            :resourceType="resourceType"
            :emailEdit="resourceEdited"
            v-on="$listeners"
        >
        </emailForm>
        <PhoneForm
            v-if="resourceData"
            :id="phoneModalId"
            :resourceData="resourceData"
            :resourceType="resourceType"
            :phoneEdit="resourceEdited"
            :phoneClassificationsOptions="phoneClassificationsOptions"
            v-on="$listeners"
        >
        </PhoneForm>
        <div v-if="!noHeader" class="d-block mb-2">
            <feather-icon icon="UsersIcon" size="28" />
            <h4 class="d-inline">
                {{ $t("menu.contacts.label") }}
            </h4>
            <feather-icon
                icon="ChevronDownIcon"
                size="28"
                class="float-right"
            />
        </div>

        <b-row class="" style="margin-left: 0.5rem">
            <feather-icon icon="PhoneIcon" size="18"></feather-icon>
            <strong
                ><span class="ml-1">{{
                    $tc("common.phone.description", 1)
                }}</span></strong
            >
            <a
                v-b-modal="phoneModalId"
                class="under-blue-text pl-3"
                @click="resourceEdited = null"
            >
                {{ $t("common.phone.add") }}
            </a>
            <div
                v-for="(phone, index) in resourceData.phones"
                :key="`index-${index}`"
                class="w-100 mt-1"
                @mouseover="showControls(index, 'phones')"
            >
                <b-row>
                    <b-col cols="12" md="5">
                        <feather-icon
                            style="cursor: auto; !important;"
                            icon="StarIcon"
                            size="15"
                            class="custom-icon mr-1"
                            :fill="
                                phone.isMainPhoneNumber ? '#519BD6' : '#ffffff'
                            "
                        ></feather-icon>
                        <span>
                            {{ maskedPhoneNumber(phone.number) }}
                        </span>
                    </b-col>
                    <b-col cols="12" md="4">
                        <span v-if="phone.phoneClassification">
                            {{ phone.phoneClassification.description }}
                        </span>
                    </b-col>
                    <b-col cols="4" md="3">
                        <feather-icon
                            v-if="
                                selected.index === index &&
                                selected.resource === 'phones' &&
                                $can('update', 'people')
                            "
                            icon="Trash2Icon"
                            size="15"
                            class="float-right under-blue-text d-inline align-middle"
                            style="margin-right: 0.5rem"
                            @click="deletePhone(phone)"
                        ></feather-icon>
                        <feather-icon
                            v-if="
                                selected.index === index &&
                                selected.resource === 'phones' &&
                                $can('update', 'people')
                            "
                            v-b-modal="phoneModalId"
                            icon="Edit2Icon"
                            size="15"
                            class="float-right under-blue-text d-inline align-middle mr-1"
                            @click="resourceEdited = phone"
                        ></feather-icon>
                    </b-col>
                </b-row>
            </div>

            <b-col cols="12" md="12" class="pl-0 pr-0 mt-2">
                <feather-icon icon="AtSignIcon" size="18"></feather-icon>
                <strong
                    ><span class="ml-1">{{
                        $tc("common.email.description", 1)
                    }}</span></strong
                >
                <a
                    v-b-modal="emailModalId"
                    class="under-blue-text pl-3"
                    @click="resourceEdited = null"
                >
                    {{ $t("common.email.add") }}
                </a>
                <div
                    v-for="(email, index) in resourceData.emails"
                    :key="`index-${index}`"
                    class="mt-1"
                    @mouseover="showControls(index, 'emails')"
                >
                    <feather-icon
                        icon="StarIcon"
                        size="15"
                        class="custom-icon mr-1"
                        :fill="email.isMainEmailAddress ? '#519BD6' : '#ffffff'"
                    ></feather-icon>
                    <span>
                        {{ email.address }}
                    </span>

                    <feather-icon
                        v-if="
                            selected.index === index &&
                            selected.resource === 'emails' &&
                            $can('update', 'people')
                        "
                        icon="Trash2Icon"
                        size="15"
                        class="float-right under-blue-text d-inline align-middle"
                        style="margin-right: 0.5rem"
                        @click="deleteEmail(email)"
                    ></feather-icon>
                    <feather-icon
                        v-if="
                            selected.index === index &&
                            selected.resource === 'emails' &&
                            $can('update', 'people')
                        "
                        v-b-modal="emailModalId"
                        icon="Edit2Icon"
                        size="15"
                        class="float-right under-blue-text d-inline align-middle mr-1"
                        @click="resourceEdited = email"
                    ></feather-icon>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import EmailForm from "@/views/components/utilis-spa/email/AttachEmailModal.vue"
import PhoneForm from "@/views/components/utilis-spa/phone/AttachPhoneModal.vue"

export default {
    components: {
        BRow,
        BCol,
        BCard,
        EmailForm,
        PhoneForm,
    },
    props: {
        noHeader: {
            required: false,
            type: Boolean,
            default: false,
        },
        cardBodyClass: {
            required: false,
            type: String,
            default: null,
        },
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        phoneModalId: {
            required: false,
            type: String,
            default: "add-phone",
        },
        emailModalId: {
            required: false,
            type: String,
            default: "add-email",
        },
        phoneClassificationsOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            selected: "",
            resourceEdited: null,
        }
    },
    methods: {
        showControls(index, resource) {
            this.selected = { index, resource }
        },
        hideControls() {
            this.selected = ""
        },

        async deletePhone(phone) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one(this.resourceType, this.resourceData.id)
                    .relationships("phones")
                    .destroy([
                        {
                            type: "phones",
                            id: phone.id,
                        },
                    ])

                this.$emit("deletePhone", phone)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        async deleteEmail(email) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one(this.resourceType, this.resourceData.id)
                    .relationships("emails")
                    .destroy([
                        {
                            type: "emails",
                            id: email.id,
                        },
                    ])
                this.$emit("deleteEmail", email)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped>
.custom-icon {
    text-decoration: underline;
    text-decoration-color: #519bd6;
    color: #519bd6;
    font-size: 1rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"create-companies-modal","centered":"","hideFooter":"","title":_vm.$t('modules.companies.new')},on:{"show":_vm.setAttributesDefaultValues},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',[_c('div',{staticClass:"ml-2 mr-2 mt-1"},[_c('formulateForm',{attrs:{"name":"companiesForm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.originsOptions)?_c('formulateInput',{attrs:{"label":_vm.$t('common.crud.recommendation'),"name":"origin","placeholder":_vm.$t('common.crud.recommendation'),"type":"select","validation":"optional|max:32,length","options":_vm.originsOptions.map(function (el) { return ({
                                    value: el.id,
                                    label: el.description,
                                }); })}}):_vm._e()],1),_c('b-col',{staticClass:"mt-2 mb-2",attrs:{"cols":"12"}},[_c('vue-autosuggest',{attrs:{"id":"name","suggestions":_vm.companiesOptions,"limit":15,"inputProps":{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: _vm.$t('common.crud.name'),
                            }},on:{"input":_vm.onSearchCompaniesByName,"selected":_vm.setResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}},[_c('template',{slot:"before-input"},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("common.crud.name")))])])],2)],1),(_vm.createdCompany)?_c('b-col',{attrs:{"cols":"12"}},[_c('contact-card',{staticStyle:{"box-shadow":"none"},attrs:{"resourceType":"companies","resourceData":_vm.createdCompany,"phoneClassificationsOptions":_vm.phoneClassificationsOptions,"noHeader":true,"phoneModalId":"create:person:add-phone","emailModalId":"create:person:add-email"},on:{"deletePhone":function($event){_vm.createdCompany.phones = _vm.createdCompany.phones.filter(
                                    function (el) { return el.id !== $event.id; }
                                )},"attachPhone":function($event){return _vm.attachPhone($event)},"deleteEmail":function($event){_vm.createdCompany.emails = _vm.createdCompany.emails.filter(
                                    function (el) { return el.id !== $event.id; }
                                )},"attachEmail":function($event){return _vm.attachEmail($event)}}})],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"mt-3"},[(!_vm.createdCompany)?_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || _vm.isLoading,"label":_vm.isLoading
                                    ? _vm.$t('common.crud.creating')
                                    : _vm.$t('common.crud.create'),"inputClass":"btn btn-primary","type":"submit"},on:{"click":_vm.handleSubmit}})],1):_vm._e(),(_vm.createdCompany)?_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || _vm.isLoading,"label":_vm.$t('common.crud.edit'),"inputClass":"btn btn-primary","type":"button"},on:{"click":function($event){return _vm.changeRoute(_vm.createdCompany.id)}}})],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hide}})],1)],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
import i18n from "@/libs/i18n"

export default [
    {
        path: "/management/system-records",
        name: "list-available-system-records",
        component: () =>
            import(
                "@/views/app/management/system-records/AvailableSystemRecordsList.vue"
            ),
        meta: {
            i18nTitle: {
                tc: "modules.systemRecords.description",
                index: 2,
            },
            pageTitle: i18n.tc("modules.systemRecords.description", 2),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.systemRecords.description",
                        index: 2,
                    },
                    active: true,
                },
            ],
            action: "list",
            resource: "system-records",
        },
    },
    {
        path: "/management/system-records/:name/list",
        name: "list-system-record-items",
        component: () =>
            import(
                "@/views/app/management/system-records/SystemRecordsList.vue"
            ),
        meta: {
            i18nTitle: {
                tc: "modules.systemRecords.description",
                index: 2,
            },
            pageTitle: i18n.tc("modules.systemRecords.description", 2),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.systemRecords.description",
                        index: 2,
                    },
                    to: { name: "list-available-system-records" },
                },
                {
                    i18nText: {
                        tc: "modules.systemRecords.recordItems",
                        active: true,
                        index: 2,
                    },
                    active: true,
                },
            ],
            action: "list",
            resource: "system-records",
        },
    },
]

<template>
    <!-- eslint-disable vue/no-mutating-props -->
    <vue-autosuggest
        v-model="context.model"
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
        :suggestions="suggestions"
        v-bind="context.attributes"
        :limit="15"
        :inputProps="{
            id: 'autosuggest__input',
            class: 'form-control',
            placeholder: $attrs.placeholder,
        }"
        :getSuggestionValue="getSuggestionValue"
        @selected="$emit('selected', $event)"
        @input="onSearch($event)"
    >
        <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">
                {{ suggestion.item.description }}
            </span>
        </template>
    </vue-autosuggest>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest"
import _ from "lodash"
import Api from "@/services/api"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        VueAutosuggest,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            suggestions: [],
        }
    },
    methods: {
        getSuggestionValue(suggestion) {
            return suggestion.item.description
        },

        onSearch(search) {
            if (!search || search.length < 3) return
            this.$emit("update:input", search)
            this.debouncedSearch(search)
        },
        debouncedSearch: _.debounce(async function searchProducts(search) {
            try {
                const products = await Api.findAll("products", {
                    filter: { description: search },
                    include: "category,unitOfMeasurement",
                })
                this.$emit("updateProductOptions", products.data)
                this.suggestions = [
                    {
                        data: products.data,
                    },
                ]
            } catch (error) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        }, 500),
    },
}
</script>

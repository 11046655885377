import FormulateCustomDate from "./Date.vue"
import FormulateDateRange from "./DateRange.vue"

export default function FormulateCustomDatePlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateCustomDate,
            FormulateDateRange,
        },
        library: {
            "custom-date": {
                classification: "custom-date",
                component: "FormulateCustomDate",
            },
            "date-range": {
                classification: "date-range",
                component: "FormulateDateRange",
            },
        },
    })
}

import Vue from "vue"
// axios
import axios from "axios"
import router from "@/router"

// VUE_API_URL used for vue-cli builds and MIX for building with laravel
const baseURL = process.env.VUE_APP_API_URL
const withCredentials = true

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL,
    withCredentials,
    // timeout: 1000,
    headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        "Utilis-Tenant": window.location.host,
    },
})

axiosIns.interceptors.response.use(
    response => Promise.resolve(response),
    error => {
        if (error.response.status === 401) {
            window.localStorage.clear()
            router.push({ name: "auth-login" })
        }
        return Promise.reject(error)
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns

<template>
    <b-modal
        v-if="$can('update', 'people')"
        v-bind="$attrs"
        ref="attach-email"
        centered
        :title="
            $tc('common.crud.attaching', 2) +
            ' ' +
            $tc('common.email.description', 1).toLowerCase()
        "
        hideFooter
        @hide="setAttributesDefaultValues"
        @show="setAttributesDefaultValues"
    >
        <b-row>
            <b-col>
                <FormulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="emailForm"
                    @submit="handleSubmit"
                >
                    <FormulateInput
                        :label="$tc('common.email.address', 1)"
                        name="address"
                        placeholder="example@example.com"
                        type="text"
                        validation="required|max:255,length|email"
                    />

                    <feather-icon
                        icon="StarIcon"
                        size="15"
                        class="under-blue-text"
                        :fill="
                            formData.isMainEmailAddress ? '#519BD6' : '#ffffff'
                        "
                        @click="
                            formData.isMainEmailAddress = !formData.isMainEmailAddress
                        "
                    ></feather-icon>
                    <span
                        @click="
                            formData.isMainEmailAddress = !formData.isMainEmailAddress
                        "
                    >
                        {{
                            $t("common.crud.isMainResource", {
                                resource: $tc(
                                    "common.email.address"
                                ).toLowerCase(),
                            })
                        }}
                    </span>

                    <formulate-input
                        v-model="formData.isMainEmailAddress"
                        type="checkbox"
                        inputClass="d-none"
                        class="d-inline-block"
                    />

                    <b-row class="mt-3">
                        <b-col class="d-flex justify-content-center">
                            <FormulateErrors />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                v-if="!emailEdit"
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $tc('common.crud.attaching', 1)
                                        : $tc('common.crud.attaching', 2)
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                            />

                            <FormulateInput
                                v-else
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $t('common.crud.updating')
                                        : $t('common.crud.update')
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                                class="pr-2"
                            />
                        </b-col>

                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                :label="$t('common.crud.close')"
                                inputClass="btn btn-danger"
                                type="button"
                                @click="hideModal"
                            />
                        </b-col>
                    </b-row>
                </FormulateForm>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BModal,
        BRow,
        BCol,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        emailEdit: {
            required: false,
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            formData: {
                address: "",
                isMainEmailAddress: false,
            },
        }
    },
    methods: {
        setAttributesDefaultValues() {
            if (this.emailEdit) {
                this.formData.address = this.emailEdit.address
                this.formData.isMainEmailAddress = this.emailEdit.isMainEmailAddress
            } else {
                this.formData.address = ""
                this.formData.isMainEmailAddress = false
            }
        },
        async handleSubmit() {
            let emailResponse = null
            try {
                if (this.emailEdit) {
                    emailResponse = await Api.update("emails", {
                        id: this.emailEdit.id,
                        address: this.formData.address,
                        isMainEmailAddress: this.formData.isMainEmailAddress,
                        owner: {
                            type: this.resourceType,
                            id: this.resourceData.id,
                        },
                    })
                } else {
                    emailResponse = await Api.create("emails", {
                        address: this.formData.address,
                        isMainEmailAddress: this.formData.isMainEmailAddress,
                        owner: {
                            type: this.resourceType,
                            id: this.resourceData.id,
                        },
                    })
                }

                if (this.emailEdit) this.$emit("deleteEmail", this.emailEdit)
                this.$emit("attachEmail", emailResponse.data)

                this.$toast(toastMessages.getSuccessMessage())

                this.hideModal()
            } catch (e) {
                this.$formulate.handle(e, "emailForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        showModal() {
            this.setAttributesDefaultValues()
            this.$refs["attach-email"].show()
        },
        hideModal() {
            this.$formulate.resetValidation("emailForm")
            this.$refs["attach-email"].hide()
        },
    },
}
</script>

<style scoped></style>

import i18n from "@/libs/i18n"

export default [
    {
        path: "/people",
        name: "list-people",
        component: () =>
            import("@/views/app/people/people-list/PeopleList.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.people.description",
                index: 2,
            },
            pageTitle: i18n.tc("modules.people.description", 2),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.people.description",
                        index: 2,
                    },
                    active: true,
                },
            ],
            action: "list",
            resource: "people",
        },
    },
    {
        path: "/people/:id",
        name: "view-people",
        component: () =>
            import("@/views/app/people/people-view/PeopleView.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.people.description",
                index: 1,
            },
            pageTitle: i18n.tc("modules.people.description", 1),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.people.description",
                        index: 2,
                    },
                    to: { name: "list-people" },
                },
                {
                    i18nText: {
                        tc: "modules.people.description",
                        index: 1,
                    },
                    active: true,
                },
            ],
            action: "view",
            resource: "people",
        },
    },
]

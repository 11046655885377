import Vue from "vue"
import VueRouter from "vue-router"

// Routes
import { canNavigate } from "@/libs/acl/routeProtection"
import {
    isUserLoggedIn,
    getUserData,
    getHomeRouteForLoggedInUser,
} from "@/auth/utils"
import pages from "./routes/pages"
import profile from "./routes/profile"
import systemManagement from "./routes/system-management"
import contacts from "./routes/contacts"
import actions from "./routes/actions"
import donations from "./routes/donations"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: "/", redirect: { name: "list-people" } },
        ...profile,
        ...pages,
        ...actions,
        ...systemManagement,
        ...contacts,
        ...donations,
        {
            path: "*",
            redirect: "error-404",
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to) && to.name !== "auth-reset-password") {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: "auth-login" })

        // If logged in => not authorized
        return next({ name: "misc-not-authorized" })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

export default router

<template>
    <!-- eslint-disable vue/no-mutating-props -->
    <v-select
        v-model="context.model"
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
        :options="options ? options : context.options"
        :value="context.model"
        :reduce="(x) => x.value"
        v-bind="context.attributes"
        deselectFromDropdown
        @input="$emit('update:input', $event)"
        @close="onClose"
        @search="onSearch"
    >
        <template #no-options="{ search, searching }">
            <template v-if="searching && search.length >= 3">
                <p style="opacity: 0.5">
                    {{ $t("components.select.label.notFound") }}
                </p>
            </template>
            <p v-else style="opacity: 0.5">
                {{ $t("components.select.label.search") }}
            </p>
        </template>
    </v-select>
</template>

<script>
import vSelect from "vue-select"
import Api from "@/services/api"
import _ from "lodash"

export default {
    components: {
        "v-select": vSelect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        /* The apiData prop has a default structure that allows (devour) client api to generate its requests based on
         * the resource value in the apiData object and its sparce fields. The fieldsMapping object is used to map
         * labels and values used to configure v-select component.
         * Ex.: apiData: {
                        resource: 'resourceName',
                        fields: ['name'],
                        fieldsMapping: {
                            label: 'name',
                            value: 'id',
                        }
                    }
         */
        apiData: {
            type: Object,
            required: true,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            options: null,
        }
    },
    methods: {
        onClose() {
            this.context.blurHandler()
        },

        onSearch(search, loading) {
            if (escape(search) === "" || search.length < 3) return
            loading(true)
            this.sendRequest(search, loading, this)
        },

        sendRequest: _.debounce(async (search, loading, thisComponent) => {
            const filterObj = {}
            filterObj[thisComponent.apiData.fieldsMapping.label] = search

            const response = await Api.findAll(thisComponent.apiData.resource, {
                filter: filterObj,
                fields: thisComponent.apiData.fields,
            })
            const result = response.data.map(
                el => ({
                    value: el[thisComponent.apiData.fieldsMapping.value],
                    label: el[thisComponent.apiData.fieldsMapping.label],
                }),
                thisComponent
            )
            thisComponent.$emit("update:options", result)
            // eslint-disable-next-line vue/no-mutating-props,no-param-reassign
            thisComponent.options = result
            loading(false)
        }, 500),
    },
}
</script>

<template>
    <b-modal
        v-if="$can('update', 'people')"
        v-bind="$attrs"
        ref="attach-phone"
        centered
        :title="
            $tc('common.crud.attaching', 2) +
            ' ' +
            $tc('common.phone.description', 1).toLowerCase()
        "
        hideFooter
        @hide="setAttributesDefaultValues"
        @show="setAttributesDefaultValues"
    >
        <b-row>
            <b-col>
                <FormulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="phoneForm"
                    @submit="handleSubmit"
                >
                    <FormulateInput
                        :label="$t('common.phone.number')"
                        name="number"
                        placeholder="(61) 9 0000-0000"
                        type="text"
                        maxlength="11"
                        validation="required|phone"
                        :validationMessages="{
                            phone: $t('common.crud.errors.invalidPhone'),
                        }"
                    />
                    <feather-icon
                        icon="StarIcon"
                        size="15"
                        class="under-blue-text"
                        :fill="
                            formData.isMainPhoneNumber ? '#519BD6' : '#ffffff'
                        "
                        @click="
                            formData.isMainPhoneNumber = !formData.isMainPhoneNumber
                        "
                    ></feather-icon>
                    <span
                        @click="
                            formData.isMainPhoneNumber = !formData.isMainPhoneNumber
                        "
                    >
                        {{
                            $t("common.crud.isMainResource", {
                                resource: $tc(
                                    "common.phone.number"
                                ).toLowerCase(),
                            })
                        }}
                    </span>

                    <formulate-input
                        name="phoneClassification"
                        type="select"
                        inputClass="form-control"
                        :label="$t('common.phone.phoneClassification')"
                        class="mt-2"
                        :options="
                            phoneClassificationsOptions.map((el) => ({
                                value: el.id,
                                label: el.description,
                            }))
                        "
                    >
                    </formulate-input>

                    <b-row class="mt-3">
                        <b-col class="d-flex justify-content-center">
                            <FormulateErrors />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                v-if="!phoneEdit"
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $tc('common.crud.attaching', 1)
                                        : $tc('common.crud.attaching', 2)
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                            />

                            <FormulateInput
                                v-else
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $t('common.crud.updating')
                                        : $t('common.crud.update')
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                                class="pr-2"
                            />
                        </b-col>

                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                :label="$t('common.crud.close')"
                                inputClass="btn btn-danger"
                                type="button"
                                @click="hideModal"
                            />
                        </b-col>
                    </b-row>
                </FormulateForm>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BModal,
        BRow,
        BCol,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        phoneEdit: {
            false: true,
            type: Object,
            default: null,
        },
        phoneClassificationsOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            formData: {
                number: "",
                isMainPhoneNumber: false,
                phoneClassification: "",
            },
        }
    },
    methods: {
        setAttributesDefaultValues() {
            if (this.phoneEdit) {
                this.formData.number = this.phoneEdit.number
                this.formData.isMainPhoneNumber = this.phoneEdit.isMainPhoneNumber
                this.formData.phoneClassification = this.phoneEdit
                    .phoneClassification
                    ? this.phoneEdit.phoneClassification.id
                    : ""
            } else {
                this.formData.number = ""
                this.formData.isMainPhoneNumber = false
                this.formData.phoneClassification = ""
            }
        },
        async handleSubmit() {
            let phoneResponse = null
            let phoneClassification = null
            try {
                if (this.phoneEdit) {
                    phoneResponse = await Api.update("phones", {
                        id: this.phoneEdit.id,
                        number: this.formData.number,
                        isMainPhoneNumber: this.formData.isMainPhoneNumber,
                        owner: {
                            type: this.resourceType,
                            id: this.resourceData.id,
                        },
                        phoneClassification: this.formData.phoneClassification
                            ? {
                                  type: "phone-classifications",
                                  id: this.formData.phoneClassification,
                              }
                            : null,
                    })
                } else {
                    phoneResponse = await Api.create("phones", {
                        number: this.formData.number,
                        isMainPhoneNumber: this.formData.isMainPhoneNumber,
                        owner: {
                            type: this.resourceType,
                            id: this.resourceData.id,
                        },
                        phoneClassification: this.formData.phoneClassification
                            ? {
                                  type: "phone-classifications",
                                  id: this.formData.phoneClassification,
                              }
                            : null,
                    })
                }

                if (this.formData.phoneClassification)
                    phoneClassification = {
                        id: this.formData.phoneClassification,
                        description: this.phoneClassificationsOptions.filter(
                            phone =>
                                phone.id === this.formData.phoneClassification
                        )[0].description,
                    }

                if (this.phoneEdit) this.$emit("deletePhone", this.phoneEdit)
                this.$emit("attachPhone", {
                    ...phoneResponse.data,
                    phoneClassification,
                })

                this.$toast(toastMessages.getSuccessMessage())

                this.hideModal()
            } catch (e) {
                this.$formulate.handle(e, "phoneForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        showModal() {
            this.setAttributesDefaultValues()
            this.$refs["attach-phone"].show()
        },
        hideModal() {
            this.$formulate.resetValidation("phoneForm")
            this.$refs["attach-phone"].hide()
        },
    },
}
</script>

<style scoped></style>

const get = require("lodash/get")

const defaultGenericErrorMessage = "Ocorreu um erro ao realizar o processo"

function handleAxiosLaravelError(error) {
    const errorResponse = get(error, "response.data")
    if (errorResponse) {
        return {
            inputErrors: errorResponse.errors,
            formErrors: [
                get(errorResponse, "message", defaultGenericErrorMessage),
            ],
        }
    }

    return {
        inputErrors: {},
        formErrors: [defaultGenericErrorMessage],
    }
}

/**
 * Error handler used on VueFormulate to handle the backend server errors
 * @param error - Default JsonApi error object
 * @see {@link https://vueformulate.com/guide/forms/error-handling/#error-handling-2}
 * @see {@link https://jsonapi.org/format/#error-objects}
 * @returns {{inputErrors: {}, formErrors: []}}
 */
export default function backendErrorHandler(error) {
    // inputErrors: { fieldName: ['Unknown email'] },
    // formErrors: ['Unknown error ocurred']
    let inputErrors = {}
    let formErrors = []

    // Default Laravel Error messages (using axios instead of JsonApi Devour)
    if (error.isAxiosError) {
        const formattedError = handleAxiosLaravelError(error)
        inputErrors = formattedError.inputErrors
        formErrors = formattedError.formErrors
    } else if (Object.keys(error).length > 0) {
        Object.keys(error).forEach(field => {
            if (get(error[field], "status") === "422") {
                // handle input error
                inputErrors[field] = get(error[field], "detail")

                formErrors.push(
                    get(error[field], "detail") ||
                        `${defaultGenericErrorMessage} - Status: ${error[field].status} - Status Text: ${error[field].title}`
                )
            } else if (get(error[field], "title") === "Network Error") {
                formErrors.push(
                    "Erro de conexão. Verifique a sua conectividade à internet!"
                )
            } else {
                formErrors.push(
                    `${defaultGenericErrorMessage} - ${error[field].details} `
                )
            }
        })
    }

    return {
        inputErrors,
        formErrors,
    }
}

import JsonApi from "devour-client"
import router from "@/router"
import _ from "lodash"

// VUE_API_URL used for vue-cli builds and MIX for building with laravel
const baseUrl = process.env.VUE_APP_API_URL

const jsonApi = new JsonApi({
    apiUrl: `${baseUrl}/api/v1`,
    pluralize: false,
    errorBuilder: error => {
        // adding fields to error's message
        const { title, detail, status, source } = error
        return { title, detail, status, source }
    },
})

jsonApi.axios.defaults.withCredentials = true
jsonApi.axios.defaults.headers.common["Utilis-Tenant"] = window.location.host
jsonApi.axios.defaults.baseURL = baseUrl

const errorMiddleware = {
    name: "invalid-session-middleware",
    error(payload) {
        if (_.get(payload, "response.status") === 401) {
            localStorage.clear()
            // Redirect to login page
            router.push({ name: "auth-login" })
        }
        return payload
    },
}

jsonApi.insertMiddlewareBefore("errors", errorMiddleware)

jsonApi.define("permissions", {
    name: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    roles: {
        jsonApi: "hasMany",
    },
})

jsonApi.define("roles", {
    name: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    permissions: {
        jsonApi: "hasMany",
    },
})

jsonApi.define("users", {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    emailVerifiedAt: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    roles: {
        jsonApi: "hasMany",
    },
    status: {
        jsonApi: "hasOne",
        type: "status",
    },
})

jsonApi.define("people", {
    name: "",
    socialName: "",
    birthDate: "",
    documentNumber: "",
    identityCard: "",
    statusReason: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    correspondence: {
        jsonApi: "hasOne",
    },
    inactivationReason: {
        jsonApi: "hasOne",
    },
    salutation: {
        jsonApi: "hasOne",
    },
    scholarity: {
        jsonApi: "hasOne",
    },
    newsletter: {
        jsonApi: "hasOne",
    },
    organizationContact: {
        jsonApi: "hasOne",
    },
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    gender: {
        jsonApi: "hasOne",
        type: "genders",
    },
    phones: {
        jsonApi: "hasMany",
        type: "phones",
    },
    emails: {
        jsonApi: "hasMany",
        type: "emails",
    },
    origin: {
        jsonApi: "hasOne",
        type: "origin",
    },
    status: {
        jsonApi: "hasOne",
        type: "status",
    },
    classifications: {
        jsonApi: "hasMany",
        type: "person-classifications",
    },
    relations: {
        jsonApi: "hasMany",
        type: "person-relationships",
    },
    addresses: {
        jsonApi: "hasMany",
        type: "addresses",
    },
    notes: {
        jsonApi: "hasMany",
        type: "notes",
    },
    tasks: {
        jsonApi: "hasMany",
        type: "tasks",
    },
    companies: {
        jsonApi: "hasMany",
        type: "companies",
    },
    materialDonations: {
        jsonApi: "hasMany",
        type: "material-donations",
    },
})

jsonApi.define(
    "genders",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/genders",
    }
)

jsonApi.define(
    "phone-classifications",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/phone-classifications",
    }
)

jsonApi.define(
    "address-classifications",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/address-classifications",
    }
)

jsonApi.define(
    "addresses-repository",
    {
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
        complement: "",
        locality: "",
        neighborhood: "",
        postal_code: "",
        state: "",
        street: "",
        addresses: {
            jsonApi: "hasMany",
            type: "addresses",
        },
    },
    {
        collectionPath: "system-records/addresses-repository",
    }
)

jsonApi.define(
    "business-segments",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/business-segment",
    }
)

jsonApi.define(
    "person-relationships",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/person-relationships",
    }
)

jsonApi.define(
    "person-classifications",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/person-classifications",
    }
)

jsonApi.define(
    "company-relationships",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/company-relationships",
    }
)

jsonApi.define(
    "company-classifications",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/company-classifications",
    }
)

jsonApi.define(
    "origin",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/origin",
    }
)

jsonApi.define(
    "status",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/status",
    }
)

jsonApi.define("emails", {
    address: "",
    isMainEmailAddress: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    owner: {
        jsonApi: "hasOne",
    },
})

jsonApi.define("phones", {
    number: "",
    isMainPhoneNumber: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    owner: {
        jsonApi: "hasOne",
    },
    phoneClassification: {
        jsonApi: "hasOne",
    },
})

jsonApi.define("addresses", {
    postalCode: "",
    isMainAddress: "",
    number: "",
    complement: "",
    description: "",
    reference: "",
    location: "",
    street: "",
    state: "",
    locality: "",
    repoNeighborhood: "",
    repoState: "",
    neighborhood: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    completeAddress: "",
    modelType: "",
    modelId: "",
    city: "",
    country: "",
    owner: {
        jsonApi: "hasOne",
    },
    addressClassification: {
        jsonApi: "hasOne",
    },
})

jsonApi.define("notes", {
    description: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    notable: {
        jsonApi: "hasOne",
    },
})

jsonApi.define("tasks", {
    title: "",
    description: "",
    completedAt: "",
    status: "",
    dueDate: "",
    priority: "",
    assignee: {
        jsonApi: "hasOne",
    },
    taskable: {
        jsonApi: "hasOne",
    },
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    completedBy: {
        jsonApi: "hasOne",
    },
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    completer: {
        jsonApi: "hasOne",
    },
})

jsonApi.define("companies-people", {
    companyId: "",
    personId: "",
    jobDescription: "",
    extension: "",
    isPrimaryContact: "",
    assistent: "",
    assistentPhone: "",
    assistentExtension: "",
    assistentEmail: "",
    deletedAt: "",
    createdAt: "",
    updatedAt: "",
    person: {
        jsonApi: "hasOne",
        type: "people",
    },
    company: {
        jsonApi: "hasOne",
        type: "companies",
    },
})

jsonApi.define("companies", {
    tradingName: "",
    name: "",
    businessTaxId: "",
    websiteUrl: "",
    foundationDate: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    stateRegistration: "",
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    statusReason: "",
    businessSegment: {
        jsonApi: "hasOne",
        type: "business-segments",
    },
    phones: {
        jsonApi: "hasMany",
        type: "phones",
    },
    emails: {
        jsonApi: "hasMany",
        type: "emails",
    },
    origin: {
        jsonApi: "hasOne",
        type: "origin",
    },
    status: {
        jsonApi: "hasOne",
        type: "status",
    },
    correspondence: {
        jsonApi: "hasOne",
        type: "correspondences",
    },
    inactivationReason: {
        jsonApi: "hasOne",
        type: "inactivation-reasons",
    },
    newsletter: {
        jsonApi: "hasOne",
        type: "newsletters",
    },
    classifications: {
        jsonApi: "hasMany",
        type: "company-classifications",
    },
    relations: {
        jsonApi: "hasMany",
        type: "company-relationships",
    },
    addresses: {
        jsonApi: "hasMany",
        type: "addresses",
    },
    notes: {
        jsonApi: "hasMany",
        type: "notes",
    },
    tasks: {
        jsonApi: "hasMany",
        type: "tasks",
    },
    people: {
        jsonApi: "hasMany",
        type: "people",
    },
    materialDonations: {
        jsonApi: "hasMany",
        type: "material-donations",
    },
})

jsonApi.define("actions", {
    title: "",
    initialPeriod: "",
    finalPeriod: "",
    budgetAccount: "",
    budget: "",
    objective: "",
    description: "",
    actionsTotalCost: "",
    monthlyDonations: "",
    recurringDonors: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    notes: {
        jsonApi: "hasMany",
        type: "notes",
    },
    tasks: {
        jsonApi: "hasMany",
        type: "tasks",
    },
    administrators: {
        jsonApi: "hasMany",
        type: "people",
    },
    coordinators: {
        jsonApi: "hasMany",
        type: "people",
    },
    actionCategory: {
        jsonApi: "hasOne",
        type: "action-categories",
    },
    goalCategory: {
        jsonApi: "hasOne",
        type: "goal-categories",
    },
    responsible: {
        jsonApi: "hasOne",
        type: "people",
    },
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
})

jsonApi.define("material-donations", {
    observation: "",
    withdrawalDate: "",
    schedulingDate: "",
    materialDonationStatusReason: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    products: {
        jsonApi: "hasMany",
    },
    contact: {
        jsonApi: "hasOne",
    },
    action: {
        jsonApi: "hasOne",
        type: "actions",
    },
    withdrawalAddress: {
        jsonApi: "hasOne",
        type: "addresses",
    },
    deliveryLocation: {
        jsonApi: "hasOne",
        type: "delivery-locations",
    },
    status: {
        jsonApi: "hasOne",
        type: "material-donation-statuses",
    },
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
})

jsonApi.define(
    "goal-categories",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/goal-categories",
    }
)

jsonApi.define("products", {
    description: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    creator: {
        jsonApi: "hasOne",
    },
    editor: {
        jsonApi: "hasOne",
    },
    destroyer: {
        jsonApi: "hasOne",
    },
    category: {
        jsonApi: "hasOne",
        type: "product-categories",
    },
    unitOfMeasurement: {
        jsonApi: "hasOne",
        type: "units-of-measurement",
    },
})

jsonApi.define(
    "material-donation-statuses",
    {
        description: "",
        systemDefaultStatus: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/material-donation-statuses",
    }
)

jsonApi.define(
    "material-donation-items",
    {
        productId: "",
        materialDonationId: "",
        quantity: "",
        unitValue: "",
        totalValue: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
        creator: {
            jsonApi: "hasOne",
        },
        editor: {
            jsonApi: "hasOne",
        },
        destroyer: {
            jsonApi: "hasOne",
        },
    },
    {
        collectionPath: "material-donation-items",
    }
)

jsonApi.define(
    "units-of-measurement",
    {
        description: "",
        symbol: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/units-of-measurement",
    }
)

jsonApi.define(
    "product-categories",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/product-categories",
    }
)

jsonApi.define(
    "action-categories",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/action-categories",
    }
)
jsonApi.define(
    "correspondences",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/correspondences",
    }
)
jsonApi.define(
    "salutations",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/salutations",
    }
)
jsonApi.define(
    "scholarities",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/scholarities",
    }
)
jsonApi.define(
    "organizations-contacts",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/organizations-contacts",
    }
)
jsonApi.define(
    "newsletters",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/newsletters",
    }
)
jsonApi.define(
    "inactivation-reasons",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    {
        collectionPath: "system-records/inactivation-reasons",
    }
)

jsonApi.define(
    "system-records",
    {
        path: "",
    },
    {
        collectionPath: "system-records",
    }
)

jsonApi.define(
    "delivery-locations",
    {
        description: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
        postalCode: "",
        number: "",
        complement: "",
        addressDescription: "",
        reference: "",
        street: "",
        state: "",
        locality: "",
        neighborhood: "",
    },
    {
        collectionPath: "system-records/delivery-locations",
    }
)
export default jsonApi

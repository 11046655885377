import Vue from "vue"
import VueI18n from "vue-i18n"

const defaultDateTimeFormats = require("./defaultDateTimeFormat.json")
const defaultNumberFormats = require("./defaultNumberFormats.json")

Vue.use(VueI18n)
function loadTranslationData() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const dateTimeFormats = {}
    const numberFormats = {}
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
            dateTimeFormats[locale] = defaultDateTimeFormats
            numberFormats[locale] = defaultNumberFormats[locale]
        }
    })
    return { messages, dateTimeFormats, numberFormats }
}

const translationData = loadTranslationData()

export default new VueI18n({
    locale: "pt-BR",
    fallbackLocale: "en",
    messages: translationData.messages,
    dateTimeFormats: translationData.dateTimeFormats,
    numberFormats: translationData.numberFormats,
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('update', 'people'))?_c('b-modal',_vm._b({ref:"attach-email",attrs:{"centered":"","title":_vm.$tc('common.crud.attaching', 2) +
        ' ' +
        _vm.$tc('common.email.description', 1).toLowerCase(),"hideFooter":""},on:{"hide":_vm.setAttributesDefaultValues,"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('b-row',[_c('b-col',[_c('FormulateForm',{attrs:{"name":"emailForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"label":_vm.$tc('common.email.address', 1),"name":"address","placeholder":"example@example.com","type":"text","validation":"required|max:255,length|email"}}),_c('feather-icon',{staticClass:"under-blue-text",attrs:{"icon":"StarIcon","size":"15","fill":_vm.formData.isMainEmailAddress ? '#519BD6' : '#ffffff'},on:{"click":function($event){_vm.formData.isMainEmailAddress = !_vm.formData.isMainEmailAddress}}}),_c('span',{on:{"click":function($event){_vm.formData.isMainEmailAddress = !_vm.formData.isMainEmailAddress}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.isMainResource", { resource: _vm.$tc( "common.email.address" ).toLowerCase(), }))+" ")]),_c('formulate-input',{staticClass:"d-inline-block",attrs:{"type":"checkbox","inputClass":"d-none"},model:{value:(_vm.formData.isMainEmailAddress),callback:function ($$v) {_vm.$set(_vm.formData, "isMainEmailAddress", $$v)},expression:"formData.isMainEmailAddress"}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateErrors')],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[(!_vm.emailEdit)?_c('FormulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$tc('common.crud.attaching', 1)
                                    : _vm.$tc('common.crud.attaching', 2),"inputClass":"btn btn-primary","type":"submit"}}):_c('FormulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$t('common.crud.updating')
                                    : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hideModal}})],1)],1)]}}],null,false,2855805379),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
export default [
    // Users management
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/app/profile/Profile.vue"),
        meta: {
            pageTitle: "Profile",
            breadcrumb: [
                { text: "Home", url: "/" },
                {
                    text: "Profile",
                    active: true,
                },
            ],
        },
    },
]

import i18n from "@/libs/i18n"

export default [
    {
        path: "/actions",
        name: "list-actions",
        component: () => import("@/views/app/actions/ActionsList.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.actions.description",
                index: 2,
            },
            pageTitle: i18n.tc("modules.actions.description", 2),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.actions.description",
                        index: 2,
                    },
                    active: true,
                },
            ],
            action: "list",
            resource: "actions",
        },
    },
    {
        path: "/actions/:id",
        name: "view-actions",
        component: () => import("@/views/app/actions/ActionsView.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.actions.description",
                index: 1,
            },
            pageTitle: i18n.tc("modules.actions.description", 1),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.actions.description",
                        index: 2,
                    },
                    to: { name: "list-actions" },
                },
                {
                    i18nText: {
                        tc: "modules.actions.description",
                        index: 1,
                    },
                    active: true,
                },
            ],
            action: "view",
            resource: "actions",
        },
    },
    {
        path: "/actions/:id/edit",
        name: "edit-actions",
        component: () => import("@/views/app/actions/ActionsEdit.vue"),
        meta: {
            i18nTitle: {
                t: "modules.actions.details",
            },
            pageTitle: i18n.t("modules.actions.details"),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.actions.description",
                        index: 2,
                    },
                    to: { name: "list-actions" },
                },
                {
                    i18nText: {
                        tc: "modules.actions.description",
                        index: 1,
                    },
                    to: { name: "view-actions" },
                },
                {
                    i18nText: {
                        t: "modules.actions.details",
                    },
                    active: true,
                },
            ],
            action: "edit",
            resource: "actions",
        },
    },
]

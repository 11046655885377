import userManagement from "./users-management"
import profile from "./roles-management"
import systemRecords from "./system-records"
import settingsManagement from "./settings-management"

const data = [
    ...userManagement,
    ...profile,
    ...systemRecords,
    ...settingsManagement,
]

export default data

<template>
    <Datepicker
        v-model="model"
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :inputClass="context.attributes.class"
        :data-type="context.type"
        :options="context.options"
        v-bind="context.attributes"
        :placeholder="
            context.attributes.hasOwnProperty('placeholder')
                ? context.attributes.placeholder
                : $i18n.t('customFormats.date')
        "
        :language="language"
        :typeable="true"
        :format="dateFormatter"
        :parser="dateParser"
        @cleared="context.rootEmit('update:date', '')"
        @input="updateDate"
    >
        <template #afterDateInput>
            <!-- eslint-disable-next-line vue/no-mutating-props -->
            <FormulateInput v-model="context.model"
                :label="$t('modules.people.assistentExtension')"
                name="date"
                :placeholder="$t('modules.people.assistentExtension')"
                type="hidden"
                validation="optional|extension"
                :validationMessages="{
                    extension: $t('common.crud.errors.invalidExtension'),
                }"
            />
        </template>
    </Datepicker>
</template>

<script>
import Datepicker from "@sum.cumo/vue-datepicker"
import * as lang from "@sum.cumo/vue-datepicker/dist/locale/index.esm"

export default {
    name: "FormulateDateRange",
    components: { Datepicker },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            language: lang[this.$i18n.locale.replace("-", "")],
        }
    },
    computed: {
        model: {
            get() {
                // Model validated with 'YYYY-MM-DD' format
                if (!this.context.model) return null

                return this.$moment(
                    this.context.model,
                    this.$moment.ISO_8601,
                    true
                ).toDate()
            },
            set(date) {
                // Date object
                if (!date) return null

                const isoDate = this.$moment(date).format("YYYY-MM-DD")

                this.context.rootEmit("update:date", isoDate)
                return isoDate
            },
        },
    },
    watch: {
        model(val){
            // eslint-disable-next-line vue/no-mutating-props
            this.context.model = this.$moment(
                val,
                this.$moment.ISO_8601,
                true
            ).toDate()
        },
    },
    methods: {
        dateFormatter(date) {
            return this.$moment(date).format(this.$t("customFormats.date"))
        },
        dateParser(date) {
            return this.$moment(date, this.$t("customFormats.date"))
        },
        updateDate(){
            const isoDate = this.$moment(this.context.model).format("YYYY-MM-DD")

            if(isoDate === 'Invalid date') return

            this.context.rootEmit("update:date", isoDate)
        }
    },
}
</script>

<style scoped>
.input > .form-control {
    background-color: #fff;
}
</style>

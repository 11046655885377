import FormulateAutosuggest from "./FormulateAutosuggest.vue"

export default function FormulateAutosuggestPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateAutosuggest,
        },
        library: {
            "vue-autosuggest": {
                classification: "autosuggest",
                component: "FormulateAutosuggest",
                slotProps: {
                    component: ["suggestions", "placeholder"],
                },
            },
        },
    })
}

import FormulateVSelectAsync from './FormulateVSelectAsync.vue'

export default function FormulateVueSelectAsyncPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateVSelectAsync,
        },
        library: {
            "vue-select-async": {
                classification: 'search',
                component: 'FormulateVSelectAsync',
                slotProps: {
                    component: ['apiData']
                }
            },
        },
    })
}

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import i18n from "@/libs/i18n"

const getSuccessMessage = () => ({
    component: ToastificationContent,
    position: "top-right",
    props: {
        title: i18n.t("common.crud.successfulOperation"),
        icon: "UserCheckIcon",
        variant: "success",
    },
})

const getUnexpectedError = () => ({
    component: ToastificationContent,
    position: "top-right",
    props: {
        title: i18n.t("common.crud.errors.unexpected"),
        icon: "XIcon",
        variant: "danger",
    },
})

const getSuccessSync = (i18nTitle, i18nRelationshipName, values) => ({
    component: ToastificationContent,
    position: "top-right",
    props: {
        title: i18n.t("common.crud.successfulSyncronization", {
            relationshipName: i18nRelationshipName.toLowerCase(),
            values: values.toString().toLowerCase(),
        }),
        icon: "UserCheckIcon",
        variant: "success",
    },
})
const getConfigMessage = (i18nTitle, featherIcon, variant) => ({
    component: ToastificationContent,
    position: "top-right",
    props: {
        title: i18n.t(i18nTitle),
        icon: featherIcon,
        variant,
    },
})

export default {
    getSuccessMessage,
    getUnexpectedError,
    getSuccessSync,
    getConfigMessage,
}

<template>
    <b-modal
        id="create-companies-modal"
        v-model="active"
        centered
        hideFooter
        :title="$t('modules.companies.new')"
        @show="setAttributesDefaultValues"
    >
        <div>
            <div class="ml-2 mr-2 mt-1">
                <formulateForm
                    v-slot="{ hasErrors }"
                    v-model="formData"
                    name="companiesForm"
                >
                    <b-row>
                        <b-col cols="12">
                            <formulateInput
                                v-if="originsOptions"
                                :label="$t('common.crud.recommendation')"
                                name="origin"
                                :placeholder="$t('common.crud.recommendation')"
                                type="select"
                                validation="optional|max:32,length"
                                :options="
                                    originsOptions.map((el) => ({
                                        value: el.id,
                                        label: el.description,
                                    }))
                                "
                            />
                        </b-col>

                        <b-col cols="12" class="mt-2 mb-2">
                            <vue-autosuggest
                                id="name"
                                v-model="formData.name"
                                :suggestions="companiesOptions"
                                :limit="15"
                                :inputProps="{
                                    id: 'autosuggest__input',
                                    class: 'form-control',
                                    placeholder: $t('common.crud.name'),
                                }"
                                @input="onSearchCompaniesByName"
                                @selected="setResult"
                            >
                                <template slot="before-input">
                                    <label for="name">{{
                                        $t("common.crud.name")
                                    }}</label>
                                </template>

                                <template slot-scope="{ suggestion }">
                                    <span class="my-suggestion-item">{{
                                        suggestion.item.name
                                    }}</span>
                                </template>
                            </vue-autosuggest>
                        </b-col>

                        <b-col v-if="createdCompany" cols="12">
                            <contact-card
                                resourceType="companies"
                                :resourceData="createdCompany"
                                style="box-shadow: none"
                                :phoneClassificationsOptions="
                                    phoneClassificationsOptions
                                "
                                :noHeader="true"
                                phoneModalId="create:person:add-phone"
                                emailModalId="create:person:add-email"
                                @deletePhone="
                                    createdCompany.phones = createdCompany.phones.filter(
                                        (el) => el.id !== $event.id
                                    )
                                "
                                @attachPhone="attachPhone($event)"
                                @deleteEmail="
                                    createdCompany.emails = createdCompany.emails.filter(
                                        (el) => el.id !== $event.id
                                    )
                                "
                                @attachEmail="attachEmail($event)"
                            >
                            </contact-card>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <formulateErrors />
                        </b-col>
                    </b-row>

                    <b-row class="mt-3">
                        <b-col
                            v-if="!createdCompany"
                            class="d-flex justify-content-center"
                        >
                            <formulateInput
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $t('common.crud.creating')
                                        : $t('common.crud.create')
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                                @click="handleSubmit"
                            />
                        </b-col>

                        <b-col
                            v-if="createdCompany"
                            class="d-flex justify-content-center"
                        >
                            <formulateInput
                                :disabled="hasErrors || isLoading"
                                :label="$t('common.crud.edit')"
                                inputClass="btn btn-primary"
                                type="button"
                                @click="changeRoute(createdCompany.id)"
                            />
                        </b-col>

                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                :label="$t('common.crud.close')"
                                inputClass="btn btn-danger"
                                type="button"
                                @click="hide"
                            />
                        </b-col>
                    </b-row>
                </formulateForm>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BRow, BCol, BModal } from "bootstrap-vue"
import { VueAutosuggest } from "vue-autosuggest"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import _ from "lodash"
import ContactCard from "@/views/components/utilis-spa/contacts/ContactCard.vue"

export default {
    name: "CompaniesCreateModal",
    components: {
        ContactCard,
        BRow,
        BCol,
        BModal,
        VueAutosuggest,
    },
    data() {
        return {
            active: false,
            formData: {
                origin: "",
                name: "",
            },
            isLoading: false,
            originsOptions: [],
            phoneClassificationsOptions: [],
            selected: "",
            companiesOptions: [],
            createdCompany: null,
        }
    },
    async created() {
        this.$eventHub.$on("showCreateCompaniesModal", this.show)
        this.$eventHub.$on("hideCreateCompaniesModal", this.hide)
        const systemRecords = await Promise.all([
            this.getOrigins(),
            this.getPhoneClassificationsOptions(),
        ])
        this.originsOptions = systemRecords[0].data
        this.phoneClassificationsOptions = systemRecords[1].data
        this.isLoading = false
    },
    methods: {
        attachPhone(phone) {
            const newCreatedCompany = _.cloneDeep(this.createdCompany)
            newCreatedCompany.phones = [
                ...newCreatedCompany.phones,
                ...[phone],
            ]

            this.createdCompany = newCreatedCompany
        },
        attachEmail(email) {
            const newCreatedCompany = _.cloneDeep(this.createdCompany)
            newCreatedCompany.emails = [
                ...newCreatedCompany.emails,
                ...[email],
            ]

            this.createdCompany = newCreatedCompany
        },
        showControls(index, resource) {
            this.selected = { index, resource }
        },
        hideControls() {
            this.selected = ""
        },
        show() {
            this.active = true
        },
        hide() {
            this.active = false
            this.setAttributesDefaultValues()
        },
        onSearchCompaniesByName(text) {
            if (text) this.search(text, this)
        },
        search: _.debounce(async (search, vm) => {
            const companies = await Api.findAll("companies", {
                filter: {
                    search,
                },
            })

            // eslint-disable-next-line no-param-reassign
            vm.companiesOptions = [
                {
                    data: companies.data,
                },
            ]
        }, 350),

        setResult(selected) {
            if (selected.item.id) {
                this.changeRoute(selected.item.id)
            }
        },
        changeRoute(value) {
            this.active = false
            this.$router.push({
                name: "view-companies",
                params: { id: value },
            })
        },
        getOrigins() {
            return Api.findAll("origin", {
                fields: { origin: "description" },
            })
        },

        getPhoneClassificationsOptions() {
            return Api.findAll("phone-classifications", {
                fields: { "phone-classifications": "description" },
            })
        },

        setAttributesDefaultValues() {
            this.formData.name = ""
            this.formData.origin = ""
            this.createdCompany = null
        },

        async handleSubmit() {
            try {
                this.isLoading = true
                const response = await Api.create("companies", {
                    name: this.formData.name,
                })
                this.createdCompany = response.data

                if (this.formData.origin !== "") {
                    await Api.one("companies", this.createdCompany.id)
                        .relationships("origin")
                        .patch({
                            type: "origins",
                            id: this.formData.origin,
                        })
                }

                this.$toast(toastMessages.getSuccessMessage())
                this.$emit("addNewCompanyToList", response.data)
            } catch (e) {
                this.$formulate.handle(e, "actionsForm")
                this.createdCompany = null
                this.$toast(toastMessages.getUnexpectedError())
                this.$bvModal.hide("create-actions-modal")
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.autocomplete .form-group {
    margin-bottom: 0;
}

.add-related-resource {
    text-decoration: underline;
    text-decoration-color: #519bd6;
    color: #519bd6;
    display: block;
}

.add-related-resource:hover {
    cursor: pointer;
}

.header-sidebar {
    background-color: #f8f8f8;
    color: #5e5873;
}

.table {
    border: none;
}

.autocomplete {
    position: relative;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 10rem;
    overflow: auto;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4aae9b;
    color: white;
}
</style>

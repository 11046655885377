import Vue from "vue"
import * as Sentry from "@sentry/vue"
import { ToastPlugin, ModalPlugin, VBTooltipPlugin } from "bootstrap-vue"
import VueCompositionAPI from "@vue/composition-api"
import _ from "lodash"

import i18n from "@/libs/i18n"
import router from "./router"
import store from "./store"
import App from "./App.vue"

// Global Components
import "./global-components"

// 3rd party plugins
import "@axios"
import "@/libs/acl"
import "@/libs/portal-vue"
import "@/libs/clipboard"
import "@/libs/toastification"
import "@/libs/sweet-alerts"
import "@/libs/vue-select"
import "@/libs/tour"
import "@/libs/vue-formulate"
import "@/libs/moment"

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VBTooltipPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css") // For form-wizard

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue() // Global event bus

Vue.prototype.$createPeopleModal = {
    show() {
        Vue.prototype.$eventHub.$emit("showCreatePeopleModal")
    },
    hide() {
        Vue.prototype.$eventHub.$emit("hideCreatePeopleModal")
    },
}
Vue.prototype.$createCompaniesModal = {
    show() {
        Vue.prototype.$eventHub.$emit("showCreateCompaniesModal")
    },
    hide() {
        Vue.prototype.$eventHub.$emit("hideCreateCompaniesModal")
    },
}

// GLOBAL MIXIN
Vue.mixin({
    methods: {
        maskedPhoneNumber(phone) {
            if (phone.length === 10) {
                // (XX) XXXX-XXXX
                return `(${phone.slice(0, 2)}) ${phone.slice(
                    2,
                    6
                )}-${phone.slice(6, 10)}`
            }
            if (phone.length === 11) {
                // (XX) XXXXX-XXXX
                return `(${phone.slice(0, 2)}) ${phone.slice(
                    2,
                    7
                )}-${phone.slice(7, 11)}`
            }

            return phone
        },
    },
})

const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount("#app")

if (
    _.get(process, "env.NODE_ENV") === "production" &&
    _.get(process, "env.VUE_APP_SENTRY_DSN")
) {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "localhost",
                    /^https:\/\/.*\.utilis\.com\.br\/api/,
                ],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('update', 'people'))?_c('b-modal',_vm._b({ref:"attach-phone",attrs:{"centered":"","title":_vm.$tc('common.crud.attaching', 2) +
        ' ' +
        _vm.$tc('common.phone.description', 1).toLowerCase(),"hideFooter":""},on:{"hide":_vm.setAttributesDefaultValues,"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('b-row',[_c('b-col',[_c('FormulateForm',{attrs:{"name":"phoneForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"label":_vm.$t('common.phone.number'),"name":"number","placeholder":"(61) 9 0000-0000","type":"text","maxlength":"11","validation":"required|phone","validationMessages":{
                        phone: _vm.$t('common.crud.errors.invalidPhone'),
                    }}}),_c('feather-icon',{staticClass:"under-blue-text",attrs:{"icon":"StarIcon","size":"15","fill":_vm.formData.isMainPhoneNumber ? '#519BD6' : '#ffffff'},on:{"click":function($event){_vm.formData.isMainPhoneNumber = !_vm.formData.isMainPhoneNumber}}}),_c('span',{on:{"click":function($event){_vm.formData.isMainPhoneNumber = !_vm.formData.isMainPhoneNumber}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.isMainResource", { resource: _vm.$tc( "common.phone.number" ).toLowerCase(), }))+" ")]),_c('formulate-input',{staticClass:"mt-2",attrs:{"name":"phoneClassification","type":"select","inputClass":"form-control","label":_vm.$t('common.phone.phoneClassification'),"options":_vm.phoneClassificationsOptions.map(function (el) { return ({
                            value: el.id,
                            label: el.description,
                        }); })}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateErrors')],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[(!_vm.phoneEdit)?_c('FormulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$tc('common.crud.attaching', 1)
                                    : _vm.$tc('common.crud.attaching', 2),"inputClass":"btn btn-primary","type":"submit"}}):_c('FormulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$t('common.crud.updating')
                                    : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hideModal}})],1)],1)]}}],null,false,3237416867),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
import FormulateVueSelect from "./FormulateVSelect.vue"

export default function FormulateVueSelectPlugin(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateVueSelect,
        },
        library: {
            "vue-select": {
                classification: "search",
                component: "FormulateVueSelect",
                slotProps: {
                    component: ["recudeLabel", "reduceFunc"],
                },
            },
        },
    })
}

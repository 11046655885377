import Vue from "vue"
import VueFormulate from "@braid/vue-formulate"
import { pt } from "@braid/vue-formulate-i18n"
import FormulateVSelectPlugin from "@/views/components/utilis-spa/vue-select/main"
import FormulateVueSelectAsyncPlugin from "@/views/components/utilis-spa/vue-select-async/formulateVSelectAsync"
import FormulateCustomDatePlugin from "@/views/components/utilis-spa/utilities/date/main"
import FormulateAutosuggestPlugin from "@/views/components/utilis-spa/vue-autosuggest/main"
import "@sum.cumo/vue-datepicker/dist/Datepicker.css"
import { sentence as s } from "@braid/vue-formulate-i18n/src/libs/formats"

// Vue Formulate
import backendErrorHandler from "./util/backendErrorHandler"

// Vue Formulate Select Plugin CSS
import "vue-select/dist/vue-select.css"

const setupBootstrap4InputClasses = context => {
    const boxTypes = ["checkbox", "radio"]
    if (boxTypes.includes(context.type)) return "form-check-input"

    // default
    return "form-control"
}

const setupBootstrap4LabelClasses = context => {
    const boxTypes = ["checkbox", "radio"]
    if (boxTypes.includes(context.type)) return "form-check-label"

    // default
    return []
}
const setupBootstrap4WrapperClasses = context => {
    const boxTypes = ["checkbox", "radio"]
    if (boxTypes.includes(context.type)) return "form-check"

    // default
    return "form-group"
}

Vue.use(VueFormulate, {
    plugins: [
        pt,
        FormulateVueSelectAsyncPlugin,
        FormulateVSelectPlugin,
        FormulateCustomDatePlugin,
        FormulateAutosuggestPlugin,
    ],
    locale: "pt",
    errorHandler: backendErrorHandler,
    useInputDecorators: false,
    classes: {
        outer: "",
        wrapper: setupBootstrap4WrapperClasses,
        label: setupBootstrap4LabelClasses,
        element: "",
        input: setupBootstrap4InputClasses,
        inputHasErrors: "is-invalid",
        help: "form-text text-muted",
        errors: "list-unstyled text-danger",
    },
    validationNameStrategy: ["validationName", "label", "name", "type"],
    rules: {
        cpf: ({ value }) =>
            value.match(/^\d{3}\d{3}\d{3}\d{2}$/) || false || value === "",
        cnpj: ({ value }) =>
            value.match(/^\d{2}\d{3}\d{3}\d{4}\d{2}$/) || false || value === "",
        phone: ({ value }) =>
            value.match(/^[0-9]{10,11}$/) || false || value === "",
        extension: ({ value }) =>
            value.match(/^[0-9]{4}$/) || false || value === "",
        validEmail: ({ value }) =>
            value.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            ),
    },
    locales: {
        en: {
            phone({ number }) {
                return `${s(
                    number
                )} is not a valid value. It's allowed only numbers with 8 to 11 digits long.`
            },
            extension({ number }) {
                return `${s(
                    number
                )} is not a valid value. It's allowed only numbers with 4 characters long.`
            },
        },
        "pt-br": {
            phone({ number }) {
                return `${s(
                    number
                )} não é um valor válido. Permitido apenas números com tamanho de 8 a 11 dígitos.`
            },
            extension({ number }) {
                return `${s(
                    number
                )} não é um valor válido. Permitido apenas números com tamanho de 4 dígitos.`
            },
        },
    },
})

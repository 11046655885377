import i18n from "@/libs/i18n"

export default [
    {
        path: "/settings",
        name: "list-system-settings",
        component: () => import("@/views/app/management/settings/Settings.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.systemSettings.description",
                index: 2,
            },
            pageTitle: i18n.tc("modules.systemSettings.description", 2),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.systemSettings.description",
                        index: 2,
                    },
                    active: true,
                },
            ],
            action: "manage",
            resource: "system-settings",
        },
    },
]

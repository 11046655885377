import i18n from "@/libs/i18n"

export default [
    {
        path: "/material-donations",
        name: "list-material-donations",
        component: () =>
            import("@/views/app/material-donations/MaterialDonationList.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.materialDonations.description",
                index: 1,
            },
            pageTitle: i18n.tc("modules.materialDonations.description", 1),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.materialDonations.description",
                        index: 1,
                    },
                    active: true,
                },
            ],
            action: "list",
            resource: "material-donations",
        },
    },
    {
        path: "/material-donations/:id",
        name: "view-material-donations",
        component: () => import("@/views/app/actions/ActionsView.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.materialDonations.description",
                index: 1,
            },
            pageTitle: i18n.tc("modules.materialDonations.description", 1),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.materialDonations.description",
                        index: 1,
                    },
                    to: { name: "list-material-donations" },
                },
                {
                    i18nText: {
                        tc: "modules.materialDonations.description",
                        index: 1,
                    },
                    active: true,
                },
            ],
            action: "view",
            resource: "material-donations",
        },
    },
    {
        path: "/material-donations/:id/edit",
        name: "edit-material-donations",
        component: () => import("@/views/app/actions/ActionsEdit.vue"),
        meta: {
            i18nTitle: {
                tc: "modules.materialDonations.details",
                index: 1,
            },
            pageTitle: i18n.t("modules.materialDonations.details"),
            breadcrumb: [
                {
                    i18nText: {
                        tc: "modules.materialDonations.description",
                        index: 1,
                    },
                    to: { name: "list-material-donations" },
                },
                {
                    i18nText: {
                        tc: "modules.materialDonations.description",
                        index: 1,
                    },
                    to: { name: "view-material-donations" },
                },
                {
                    i18nText: {
                        tc: "modules.materialDonations.details",
                        index: 1,
                    },
                    active: true,
                },
            ],
            action: "edit",
            resource: "material-donations",
        },
    },
]

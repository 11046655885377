<template>
    <v-select
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
        :value="context.model"
        :options="context.options"
        v-bind="context.attributes"
        :reduce="reduceFunc"
        :label="recudeLabel"
        deselectFromDropdown
        @input="$emit('update:input', $event)"
        @close="onClose"
    />
</template>

<script>
import vSelect from "vue-select"

export default {
    components: {
        "v-select": vSelect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        reduceFunc: {
            type: Function,
            required: false,
            default: x => x.value,
        },
        recudeLabel: {
            type: String,
            required: false,
            default: "label",
        },
    },
    methods: {
        onClose() {
            this.context.blurHandler()
        },
    },
}
</script>
